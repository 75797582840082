import React from 'react';
import styled from 'styled-components';

const HeroContainer = styled.section`
    margin-top: 50px;
    max-width: var(--max-width-container);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc( 80vh - 50px - 80px - var(--main-bottom-padding));
    width: 100%;
    gap: ${props => props.$spacing || ""};
`;

function Hero({ children, spacing}) {
    return (
        <HeroContainer $spacing={spacing}>
            {children}
        </HeroContainer>
    );
}

export default Hero;