import MenuItem from '../ui/aside-section/HeaderItems';
import { CiFileOn, CiBoxList } from 'react-icons/ci';
import { PiPaperclipLight, PiUsersThin } from "react-icons/pi";
import Accordion from '../ui/Accordion';


function MainMenu() {
    return (
        <Accordion title="Espace de travail" defaultOpen={false} highlight={false} fontSize='sm' variant='compact'>
            <ul className="menu">
                <MenuItem text="Clients" icon={PiUsersThin} variant="classique" to="/clients" />
                <MenuItem text="Tâches" icon={CiBoxList} variant="classique" to="/taches" />
                <MenuItem text="Templates" icon={CiFileOn } variant="classique" to="/audit-templates" />
                <MenuItem text="Documents" icon={PiPaperclipLight} variant="classique" to="/documents" />
            </ul>
        </Accordion>
    );
}

export default MainMenu;