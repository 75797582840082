import React, { useState, useEffect } from 'react';
import Table from '../../../components/ui/table/Table';
import Th from '../../../components/ui/table/Th';
import Td from '../../../components/ui/table/Td';
import Pastille from '../../../components/ui/textual/Pastille';
import Link from '../../../components/ui/textual/Link';
import Stack from '../../../components/box/container/Stack';
import InvisibleLink from '../../../components/ui/button/InvisibleLink';
import { generateClient } from 'aws-amplify/api';
import { getAudit, getClients, tasksByDone } from '../../../graphql/queries';
import fetchS3File from '../../../hooks/getdata/fetchs3File';
import * as Tool from '../../../tools/Calculate';

const apiClient = generateClient();

const TaskBoard = () => {
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const taskData = await apiClient.graphql({
                    query: tasksByDone,
                    variables: {
                        doneStatus: "false",
                        limit: 5,
                        sortDirection: "ASC",
                    }
                });

                const items = taskData.data.tasksByDone.items;

                const tasksWithDetails = await Promise.all(
                    items.map(async (task) => {
                        const auditData = await apiClient.graphql({
                            query: getAudit,
                            variables: { id: task.auditTasksId }
                        });

                        const audit = auditData.data.getAudit;

                        const clientData = await apiClient.graphql({
                            query: getClients,
                            variables: { id: audit.clientsAuditsId }
                        });

                        const client = clientData.data.getClients;

                        let logoUrl = null;
                        if (client.logo) {
                            logoUrl = await fetchS3File(client.logo, client.logoIdentityId);
                        }

                        return {
                            ...task,
                            audit,
                            client: {
                                ...client,
                                logoUrl,
                            }
                        };
                    })
                );

                setTasks(tasksWithDetails);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, []);

    return (
        <Table>
            <thead>
                <tr>
                    <Th variant="highlight">Tâche</Th>
                    <Th variant="highlight">Date</Th>
                    <Th variant="highlight">Audit</Th>
                    <Th variant="highlight">Client</Th>
                </tr>
            </thead>
            <tbody>
                {tasks.map((task) => (
                    <tr key={task.id}>
                        <Td>
                            <Link textTransform="capitalize" to={`/${task.client?.id}/${task.audit.id}`} cropped>
                                {task.name}
                            </Link>
                        </Td>
                        <Td>
                            <Pastille variant={Tool.defColorDate(task).color}>
                                {Tool.defColorDate(task).icon}
                                {Tool.dateToFRFormat(task)}
                            </Pastille>
                        </Td>
                        <Td>
                            <Link textTransform="capitalize" to={`/${task.client?.id}/${task.audit.id}`} cropped>
                                {task.audit?.name || 'Audit non disponible'}
                            </Link>
                        </Td>
                        <Td>
                            <Stack align={"center"}>
                                <InvisibleLink to={`/clients/${task.client?.id}`} lineheight={"0"}>
                                    <img
                                        src={task.client.logoUrl ? task.client.logoUrl : "/illustration/svg/utilisateur.svg"}
                                        alt={`${task.client.name} logo`}
                                        style={{ width: '20px', height: '20px', borderRadius: '50px' }}
                                    />
                                </InvisibleLink>
                                <Link textTransform="capitalize" to={`/clients/${task.client?.id}`} cropped>
                                    {task.client ? `${task.client.name}` : 'Client non disponible'}
                                </Link>
                            </Stack>
                        </Td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default TaskBoard;
