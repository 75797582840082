import React from "react";
import styled, { css } from "styled-components";
import ButtonLoading from "../../ButtonLoading";
import { Link } from "react-router-dom";

const ButtonComponent = styled.button`
  position: relative;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: ${props => props.$width === "fit-content"
    ? "fit-content"
    : props.$width === "full-width"
      ? "100%"
      : "fit-content"};
  padding: 10px 14px;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    box-shadow: 4px 4px ${props =>
    props.$variant === "primary"
      ? "var(--main-color)"
      : props.$variant === "secondary"
        ? "var(--color-title)"
        : "none"};
  }

  &:active {
    box-shadow: 0px 0px ${props =>
    props.$variant === "primary"
      ? "var(--main-color)"
      : props.$variant === "secondary"
        ? "var(--color-title)"
        : "none"};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    color: var(--color-title);
    border: 1px solid var(--color-title);
    box-shadow: none;
  }

  ${({ $variant, $disable }) => {
    if ($variant === "primary" && !$disable) {
      return css`
        background: var(--bg-color);
        color: var(--color-title);
        border: 1px solid var(--color-title);
        box-shadow: 6px 6px var(--main-color);
      `;
    } else if ($variant === "secondary" && !$disable) {
      return css`
        background: var(--bg-color);
        color: var(--color-title);
        border: 1px solid var(--color-title);
        box-shadow: 6px 6px var(--color-title);
      `;
    }
  }}
`;

const Button = ({ type, variant, width, className, id, onClick, children, disable, loading, data_cy, to, ...props }) => {
  const isLink = to !== undefined;

  const filteredProps = {
    $variant: variant,
    $disable: disable,
    $loading: loading,
    $width: width,
    className: className ? `btn-component ${className}` : "btn-component",
    id: id,
    onClick: !disable && !loading && !isLink ? onClick : null,
    ...props
  };

  return (
    <ButtonComponent
      as={isLink ? Link : 'button'}
      to={isLink ? to : null}
      type={isLink ? null : (type ? (type === "input" ? ["button", "input"] : type) : "button")}
      disabled={disable || loading}
      data-cy={data_cy}
      {...filteredProps}
    >
      {loading && <ButtonLoading />}
      {children}
    </ButtonComponent>
  );
};

export default Button;
