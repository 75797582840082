import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomeScreen from './pages/private/HomeScreen';
import LoginScreen from "./pages/public/LoginScreen";
import Appearance from "./pages/private/settings/Appearance";
import Authentification from "./pages/private/settings/Authentification";
import Compte from "./pages/private/settings/Compte";
import ProfilePage from "./pages/private/Profil";
import Page404 from "./pages/error/Page404";
import Error429 from "./pages/error/Error429";
import Error500 from "./pages/error/Error500";
import Clients from "./pages/private/clients/Clients";
import ClientDetails from "./pages/private/clients/ClientDetails";
import EditClients from "./pages/private/clients/EditClients";
import AddClients from "./pages/private/clients/AddClients";
import CreateAudit from "./pages/private/clients/CreateAudit";
import ListAuditTemplates from "./pages/private/ListAuditTemplates";
import CompleteProfile from './pages/private/CompleteProfile';
import CompleteProfileRoutes from './hooks/login-gestion/CompleteProfileRoutes';
import GetAudits from './pages/private/clients/audits/GetAudits';
import DefaultLayout from './layout/DefaultLayout';
import ProtectedRoute from './hooks/login-gestion/ProtectedRoute';
import Setting from './pages/private/settings/Setting';
import ListDocuments from './pages/private/documents/ListDocuments';
import Users from './pages/private/Users';
import ListAllTasks from './pages/private/tasks/ListAllTasks';

function App() {
  return (
    <Routes>
      {/* Routes publiques */}
      <Route path="/se-connecter" element={<LoginScreen />} />

      {/* Routes accessibles sans profil */}
      <Route path="/completer-mon-profil" element={<CompleteProfileRoutes><CompleteProfile /></CompleteProfileRoutes>} />

      {/* Routes protégées enveloppées par DefaultLayout */}
      <Route element={<ProtectedRoute />}>
        <Route element={<DefaultLayout bottombar={true} />}>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/parametres" element={<Setting />} />
          <Route path="/parametres/apparence" element={<Appearance />} />
          <Route path="/parametres/authentification" element={<Authentification />} />
          <Route path="/parametres/compte" element={<Compte />} />
          <Route path="/profil" element={<ProfilePage />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/clients/:id" element={<ClientDetails />} />
          <Route path="/:clientId/:auditId" element={<GetAudits />} />
          <Route path="/audit-templates" element={<ListAuditTemplates />} />
          <Route path="/documents" element={<ListDocuments />} />
          <Route path="/taches" element={<ListAllTasks />} />

          {/* Routes protégées pour les administrateurs uniquement */}
          <Route element={<ProtectedRoute adminOnly={true} />}>
            <Route path="/utilisateurs" element={<Users />} />
            <Route path="/clients/:id/modifier" element={<EditClients />} />
            <Route path="/clients/:id/ajouter-un-audit" element={<CreateAudit />} />
            <Route path="/clients/ajouter-un-client" element={<AddClients />} />
          </Route>

          <Route path="*" element={<Page404 />} />
        </Route>
      </Route>

      {/* Routes pour les erreurs spécifiques */}
      <Route path="/erreur-429" element={<Error429 />} />
      <Route path="/erreur-500" element={<Error500 />} />

      {/* Route pour la page 404 */}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export default App;
