import React, { useState, useEffect } from 'react';
import Hero from '../../components/box/section/Hero';
import Title from '../../components/ui/textual/Title';
import Table from '../../components/ui/table/Table.js';
import Pastille from '../../components/ui/textual/Pastille.js';
import Td from '../../components/ui/table/Td.js';
import Th from '../../components/ui/table/Th.js';
import Checkbox from '../../components/ui/checkbox/CheckboxItem.js';
import FormError from '../../components/ui/form/FormError.js';
import Loading from '../../states/Loading.js';
import { get, del } from 'aws-amplify/api';
import { useUser } from '../../contexts/UserContext.js';
import { usePopup } from '../../contexts/PopupContext';
import ViewUserPopup from '../../components/ui/popup/allPopups/ViewUserPopup.js';
import Link from '../../components/ui/textual/Link.js';
import { CiCircleCheck, CiMail, CiTrash, CiWarning } from 'react-icons/ci';
import { PiUserCircleDashedThin, PiUserCircleGearThin } from 'react-icons/pi';
import IconButton from '../../components/ui/button/IconButton.js';
import Stack from '../../components/box/container/Stack.js';
import AddUserPopup from '../../components/ui/popup/allPopups/AddUserPopup.js';
import { IoMdAdd } from 'react-icons/io';
import { confirm } from '../../components/confirm/ConfirmGlobal.js';
import { notifyError, notifySuccess } from '../../components/ui/Toastify.js';

function Users() {
    const { popups, openPopup, closePopup } = usePopup();
    const { user } = useUser();
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [noAccess, setNoAccess] = useState(false);
    const [loading, setLoading] = useState(true);

    async function getUsers() {
        try {
            const restOperation = get({
                apiName: 'usersApi',
                path: '/users',
                options: {
                    headers: {
                        Token: `user_token ${user.accessToken}`,
                    },
                }
            });
            const response = await restOperation.response;
            const userData = await response.body.json();
            setUsers(userData);
            setLoading(false);
        } catch (e) {
            console.error('GET users call failed: ', e);

            setError('Impossible de récupérer les utilisateurs');
            setLoading(false);
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    async function deleteUser(username) {
        try {
            const adminConfirmed = await confirm({
                title: "Voulez-vous vraiment supprimer cet utilisateur ?",
                content: "Cet utilisateur et son profil associé seront supprimés pour toujours.",
                variant: "danger"
            });
    
            if (adminConfirmed) {
                const result = await del({
                    apiName: 'usersApi',
                    path: `/users/delete/${username}`,
                    options: {
                        headers: {
                            Token: `user_token ${user.accessToken}`,
                        },
                    }
                });
    
                const response = await result.response;
    
                console.log(response);
    
                if (response.statusCode === 200) {
                    notifySuccess('Utilisateur supprimé avec succès');
                } else {
                    notifyError('Erreur lors de la suppréssion de l\'utilisateur');
                }
            }
        } catch (error) {
            notifyError('Erreur lors de la suppréssion de l\'utilisateur');
            console.error('Erreur lors de la suppréssion de l\'utilisateur :', error);
        }
    }
    


    return (
        <Hero>
            <Stack justify="space-between">
                <Title level={1}>Liste des utilisateurs</Title>
                <IconButton
                    variant="action"
                    onClick={() => openPopup("add_user")}
                    fontSize="16px"
                >
                    <IoMdAdd /> Ajouter un utilisateur
                </IconButton>
            </Stack>

            {error && <FormError variant="error">
                {error}
            </FormError>}
            {noAccess && !error && (
                <FormError variant="error">
                    Vous n'avez pas l'autorisation d'afficher les utilisateurs.
                </FormError>
            )}
            {loading && <Loading />}
            {!loading && users.length === 0 && !error && !noAccess && (
                <Title>Aucun utilisateur disponible.</Title>
            )}

            {!loading && users.length > 0 && (
                <Table>
                    <thead>
                        <tr>
                            <Th variant="basique">
                                <Checkbox type="checkbox" />
                            </Th>
                            <Th variant="basique">ID</Th>
                            <Th variant="basique">Email</Th>
                            <Th variant="basique">Groupe</Th>
                            <Th variant="basique">Confirmation statut</Th>
                            <Th variant="basique">Actions</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.username}>
                                <ViewUserPopup
                                    open={popups[`view ${user.username}`]}
                                    onClose={() => closePopup(`view ${user.username}`)}
                                    user={user}
                                />
                                <Td>
                                    <Checkbox type="checkbox" />
                                </Td>
                                <Td>
                                    <Link onClick={() => openPopup(`view ${user.username}`)} cropped>
                                        {user.username}
                                    </Link>
                                </Td>
                                <Td>
                                    <Pastille variant={"success"}><CiMail />{user.email}</Pastille>
                                </Td>
                                <Td>
                                    {user.groups && user.groups.length > 0 ? (
                                        <>
                                            {user.groups.includes('Admin') && (
                                                <Pastille variant="danger"><PiUserCircleGearThin />Administrateur</Pastille>
                                            )}
                                            {!user.groups.includes('Admin') && user.groups.includes('Reltim') && (
                                                <Pastille variant="success"><PiUserCircleDashedThin />Reltim</Pastille>
                                            )}
                                        </>
                                    ) : (
                                        <Pastille variant="warning"><CiWarning />Aucun groupe</Pastille>
                                    )}
                                </Td>
                                <Td>
                                    <>
                                        {user.status === 'CONFIRMED' && (
                                            <Pastille variant="success">
                                                <CiCircleCheck /> Confirmé
                                            </Pastille>
                                        )}
                                        {user.status === 'FORCE_CHANGE_PASSWORD' && (
                                            <Pastille variant="warning">
                                                <CiWarning /> Doit changer son mot de passe
                                            </Pastille>
                                        )}
                                        {user.status !== 'CONFIRMED' && user.status !== 'FORCE_CHANGE_PASSWORD' && (
                                            <Pastille variant="warning">
                                                <CiWarning /> {user.status || 'Non renseigné'}
                                            </Pastille>
                                        )}
                                    </>
                                </Td>
                                <Td>
                                    <IconButton variant={"danger"} wtext={"no"} onClick={() => deleteUser(user.username)}><CiTrash /></IconButton>
                                </Td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            <AddUserPopup
                open={popups["add_user"]}
                onClose={() => closePopup("add_user")}
            />
        </Hero>
    );
}

export default Users;
