import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import Loading from '../../states/Loading';
import NotFound from '../../states/NotFound';

function ProtectedRoutes({ adminOnly = false }) {
    const { isLoggedIn, profileCompleted, isAdmin } = useUser();

    // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
    if (!isLoggedIn) {
        return <Navigate to="/se-connecter" replace />;
    }

    // Afficher un écran de chargement si le profil est en cours de vérification
    if (profileCompleted === null || isLoggedIn === null || isLoggedIn === undefined) {
        return <Loading />;
    }

    // Rediriger vers la page de complétion du profil si le profil n'est pas complété
    if (profileCompleted === false) {
        return <Navigate to="/completer-mon-profil" replace />;
    }

    // Vérifiez si la route est réservée aux admins
    if (adminOnly && !isAdmin) {
        return <NotFound />;
    }

    // Rendre les routes enfants si toutes les conditions sont remplies
    return <Outlet />;
}

export default ProtectedRoutes;
