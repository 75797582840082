import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  vertical-align: middle;
  line-height: 0;
`;

const Icon = styled.svg`
  fill: none;
  stroke: var(--bg-color);
  stroke-width: 4px;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? 'var(--main-color)' : 'var(--bg-color)')};
  border-radius: 3px;
  transition: all 150ms;
  cursor: pointer;
  box-shadow: 0 0 0 1px var(--paragraph);

  &:hover:after{
    opacity:0.1;
    visibility: visible;
  }

  &:active:after{
    opacity:0.2;
    visibility: visible;
  }

  &:after{
    transition: 0.3s;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    background: var(--color-title);
    opacity: 0;
    border-radius: 50px;
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px var(--colored-text);
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const Label = styled.span`
  margin-right: 8px;
  font-size: 16px;
  line-height: 1;
  color: var(--paragraph);
`;

const Checkbox = ({ className, checked, label, ...props }) => (
  <label>
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      {label && <Label>{label}</Label>}
    </CheckboxContainer>
  </label>
);

export default Checkbox;
