import React, { useState, useEffect } from 'react';
import Title from '../../../components/ui/textual/Title';
import Hero from '../../../components/box/section/Hero';
import Container from '../../../components/box/container/Container';
import Column from '../../../components/box/container/Column';
import Bento from '../../../components/box/bento/Bento';
import Stack from '../../../components/box/container/Stack';
import Link from '../../../components/ui/textual/Link';
import { generateClient } from "aws-amplify/api";
import { getAudit, getClients, tasksByDone } from '../../../graphql/queries';
import Th from '../../../components/ui/table/Th';
import Td from '../../../components/ui/table/Td';
import Table from '../../../components/ui/table/Table';
import Pastille from '../../../components/ui/textual/Pastille';
import * as Tool from "../../../tools/Calculate";
import fetchS3File from '../../../hooks/getdata/fetchs3File';
import InvisibleLink from '../../../components/ui/button/InvisibleLink';

const apiClient = generateClient();

function ListAllTasks() {
  const [urgentTasks, setUrgentTasks] = useState([]);

  async function fetchTasks() {
    try {
      const currentDate = new Date();
      const thirtyDaysFromNow = new Date();
      thirtyDaysFromNow.setDate(currentDate.getDate() + 30);

      const taskData = await apiClient.graphql({
        query: tasksByDone,
        variables: {
          doneStatus: "false",
          limit: 100,
          sortDirection: "ASC",
        }
      });


      const items = taskData.data.tasksByDone.items;

      const tasksWithAuditsAndClients = await Promise.all(items.map(async (task) => {
        const auditData = await apiClient.graphql({
          query: getAudit,
          variables: {
            id: task.auditTasksId
          }
        });

        const audit = auditData.data.getAudit;

        const clientData = await apiClient.graphql({
          query: getClients,
          variables: {
            id: audit.clientsAuditsId
          }
        });

        const client = clientData.data.getClients;

        let logoUrl = null;
        if (client.logo) {
          logoUrl = await fetchS3File(client.logo, client.logoIdentityId);
        }

        return {
          ...task,
          audit,
          client: {
            ...client,
            logoUrl,
          }
        };
      }));

      setUrgentTasks(tasksWithAuditsAndClients);

    } catch (error) {
      console.error('Error on fetching data', error);
    }
  }

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <Hero spacing="30px">
      <Container direction="row">
        <Column width="100%">
          <Bento highlight="highlight">
            <Stack align={"center"}>
              <Title level={3}>Tâches en retard</Title>
            </Stack>
            <Table>
              <thead>
                <tr>
                  <Th variant="highlight">Tâche</Th>
                  <Th variant="highlight">Date</Th>
                  <Th variant="highlight">Audit</Th>
                  <Th variant="highlight">Client</Th>
                </tr>
              </thead>
              <tbody>
                {urgentTasks.map((task) => (
                  <tr key={task.id}>
                    <Td>
                      <Link textTransform="capitalize" to={`/${task.client?.id}/${task.audit.id}`} cropped>
                        {task.name}
                      </Link>
                    </Td>
                    <Td>
                      <Pastille variant={Tool.defColorDate(task).color}>
                        {Tool.defColorDate(task).icon}{Tool.dateToFRFormat(task)}
                      </Pastille>
                    </Td>
                    <Td>
                      <Link textTransform="capitalize" to={`/${task.client?.id}/${task.audit.id}`} cropped>
                        {task.audit?.name || 'Audit non disponible'}
                      </Link>
                    </Td>
                    <Td>
                      <Stack align={"center"}>
                        <InvisibleLink to={`/clients/${task.client?.id}`} lineheight={"0"}>
                          <img
                            src={task.client.logoUrl ? task.client.logoUrl : ("/illustration/svg/utilisateur.svg")}
                            alt={`${task.client.name} logo`}
                            style={{ width: '20px', height: '20px', borderRadius: '50px' }}
                          />
                        </InvisibleLink>
                        <Link textTransform="capitalize" to={`/clients/${task.client?.id}`} cropped>
                          {task.client ? `${task.client.name}` : 'Client non disponible'}
                        </Link>
                      </Stack>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Bento>
        </Column>
      </Container>

    </Hero>
  );
}

export default ListAllTasks;