import React, { useEffect, useState } from 'react';
import Popup from '../Popup';
import Tips from '../../textual/Tips';
import Stack from '../../../box/container/Stack';
import TextInput from '../../form/Textinput';
import Password from '../../form/Password';
import Button from '../../button/Button';
import SelectSearchable from '../../form/SelectSearchable';
import { get, post } from 'aws-amplify/api';
import { useUser } from '../../../../contexts/UserContext';
import { notifyError, notifySuccess } from '../../Toastify';
import Checkbox from '../../checkbox/CheckboxItem';
import Title from '../../textual/Title';

const AddUserPopup = ({ open, onClose }) => {
    const { user } = useUser();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [generatePassword, setGeneratePassword] = useState(true);
    const [sendEmail, setSendEmail] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [groups, setGroups] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);

    async function getGroups() {
        try {
            const restOperation = get({
                apiName: 'usersApi',
                path: '/users/groups',
                options: {
                    headers: {
                        Token: `user_token ${user.accessToken}`,
                    },
                }
            });
            const response = await restOperation.response;
            const groupData = await response.body.json();
            setGroups(groupData.map(group => ({ id: group.groupName, label: group.groupName })));
        } catch (e) {
            console.error('GET groups call failed: ', e);
        }
    }

    useEffect(() => {
        getGroups();
    }, []);

    const handleGroupSelection = (option) => {
        if (option) {
            setSelectedGroup(option);
        } else {
            setSelectedGroup(null);
        }
    };

    async function handleCreateUser() {
        setIsLoading(true);
        const groupData = {
            email,
            temporaryPassword: generatePassword ? undefined : password,
            autoGeneratePassword: generatePassword,
            sendInvitation: sendEmail,
            groups: selectedGroup ? selectedGroup.id : null, 
        };

        try {
            const result = await post({
                apiName: 'usersApi',
                path: '/users/create',
                options: {
                    headers: {
                        Token: `user_token ${user.accessToken}`,
                    },
                    body: groupData,
                }
            });

            const response = await result.response;

            if (response.statusCode === 201) {
                notifySuccess('Utilisateur créé avec succès');
                setIsLoading(false);
                onClose();
            } else {
                notifyError('Erreur lors de la création de l\'utilisateur');
                setIsLoading(false);
                onClose();
            }

        } catch (error) {
            console.error('Erreur lors de la création de l’utilisateur :', error);
            setIsLoading(false);
            notifyError('Erreur lors de la création de l\'utilisateur');
            onClose();
        }
    }

    return (
        <Popup open={open} onClose={onClose} title={"Ajouter un utilisateur"}>
            <Stack direction={"column"} spacing={"15px"}>
                <Stack direction={"column"} spacing={"20px"}>
                    <Stack direction={"column"} spacing={"30px"}>
                        <Stack direction={"column"}>
                            <TextInput
                                type="email"
                                label="Email"
                                autoComplete="new-email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                variant={"blue"}
                            />
                            <Checkbox
                                label={"Générer un mot de passe automatiquement (recommandé)"}
                                checked={generatePassword}
                                onChange={() => setGeneratePassword(!generatePassword)}
                            />
                            {!generatePassword && (
                                <Password
                                    type="password"
                                    autoComplete="new-password"
                                    label="Mot de passe temporaire"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required={!generatePassword}
                                    variant={"blue"}
                                    disabled={generatePassword}
                                />
                            )}
                            <Checkbox
                                label={"Envoyer une invitation par email (recommandé)"}
                                checked={sendEmail}
                                onChange={() => setSendEmail(!sendEmail)}
                            />
                        </Stack>
                        <Stack direction={"column"}>
                            <Title level={5}>Choisissez un groupe de permission :</Title>
                            <SelectSearchable
                                options={groups}
                                onSelect={handleGroupSelection}
                                defaultText="Sélectionnez un groupe"
                                value={selectedGroup ? selectedGroup.label : ''} 
                                label="Groupes"
                            />

                            {selectedGroup && selectedGroup.label === 'Admin' && (
                                <Tips variant="warning">
                                    Les administrateurs ont des permissions sensibles comme ajouter/supprimer des utilisateurs ou des clients, assurez-vous de leur fiabilité !
                                </Tips>
                            )}
                            {selectedGroup && selectedGroup.label === 'Reltim' && (
                                <Tips variant="success">
                                    Le groupe Reltim peut lire toutes les données sur l'application sans effectuer d'action.
                                </Tips>
                            )}
                            {!selectedGroup && (
                                <Tips variant="warning">
                                    Si vous ne choisissez aucun groupe, par défaut l'utilisateur rejoindra le groupe "Reltim" lors de sa première connexion.
                                </Tips>
                            )}
                        </Stack>
                    </Stack>
                    <Button width="full-width" variant={"primary"} onClick={handleCreateUser} loading={isLoading}>
                        Créer l'utilisateur
                    </Button>
                </Stack>
            </Stack>
        </Popup>
    );
};

export default AddUserPopup;
