import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const LinkItem = styled(NavLink)`
  display: block;
  width: fit-content;
  color: var(--main-color);
  margin: ${props => props.$margin || ""};
  transition: 0.3s ease-in-out;
  text-align: ${props => props.$textalign || ""};
  text-transform: ${props => props.$textTransform || ""};

  ${(props) =>
    props.$cropped &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  `}

  &:hover {
    color: var(--color-title);
  }
`;


const ButtonItem = styled.button`
  font-size: 20px;
  width: fit-content;
  color: var(--main-color);
  margin: ${props => props.$margin || ""};
  transition: 0.3s ease-in-out;
  text-align: ${props => props.$textalign || ""};
  text-transform: ${props => props.$textTransform || ""};
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;

  ${(props) =>
    props.$cropped &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  `}

  &:hover {
    color: var(--color-title);
  }
`;


const Link = ({ children, variant, to, margin, textalign, textTransform, onClick, cropped }) => {
  if (onClick) {
    return (
      <ButtonItem
        type="button"
        onClick={onClick}
        $cropped={cropped}
        $margin={margin}
        $textalign={textalign}
        $textTransform={textTransform}
      >
        {children}
      </ButtonItem>
    );
  }

  return (
    <LinkItem
      to={to}
      $variant={variant}
      $margin={margin}
      $cropped={cropped}
      $textalign={textalign}
      $textTransform={textTransform}
    >
      {children}
    </LinkItem>
  );
};

export default Link;
